export default {
  'pages.layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名和密码（admin/ant.design)',
  'pages.login.failure': '登录失败，请重试！',
  'pages.login.success': '登录成功！',
  'pages.login.username.placeholder': '用户名',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '密码',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号！',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.advancedComponent': '高级表格',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.advancedLayout': '高级布局',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.searchTable.createForm.newRule': '新建规则',
  'pages.searchTable.updateForm.ruleConfig': '规则配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '规则名称',
  'pages.searchTable.updateForm.ruleName.nameRules': '请输入规则名称！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '规则描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '请输入至少五个字符',
  'pages.searchTable.updateForm.ruleDesc.descRules': '请输入至少五个字符的规则描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置规则属性',
  'pages.searchTable.updateForm.object': '监控对象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '规则模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '规则类型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '设定调度周期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '规则名称为必填项',
  'pages.searchTable.titleCallNo': '服务调用次数',
  'pages.searchTable.titleStatus': '状态',
  'pages.searchTable.nameStatus.default': '关闭',
  'pages.searchTable.nameStatus.running': '运行中',
  'pages.searchTable.nameStatus.online': '已上线',
  'pages.searchTable.nameStatus.abnormal': '异常',
  'pages.searchTable.titleUpdatedAt': '上次调度时间',
  'pages.searchTable.exception': '请输入异常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '订阅警报',
  'pages.searchTable.title': '查询表格',
  'pages.searchTable.new': '新建',
  'pages.searchTable.chosen': '已选择',
  'pages.searchTable.item': '项',
  'pages.searchTable.totalServiceCalls': '服务调用次数总计',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': '批量删除',
  'pages.searchTable.batchApproval': '批量审批',
  'audit.result.ok': '成功',
  'audit.result.failure': '失败',
  'audit.login.subs.authorize_token': '登录应用__target_name__',
  'audit.login.subs.authorize': '授权应用__target_name__',
  'audit.login.subs.authorize_qrcode': '扫码登录__target_name__',
  'audit.login.subs.authorize_agree': '同意授权__target_name__',
  'audit.login.subs.authorize_refuse': '拒绝授权__target_name__',
  'audit.login.subs.singin': '登录成功',
  'audit.login.subs.signout': '登出成功',
  'audit.login.subs.change_password': '修改密码',
  'audit.system.srchPlcHdr': '操作者/操作对象',
  'audit.system.evt_types.all.text': '全部',
  'audit.system.evt_types.user.text': '用户',
  'audit.system.evt_types.org.text': '组织架构',
  'audit.system.evt_types.portal.text': '个人门户',
  'audit.system.evt_types.role.text': '权限',
  'audit.system.evt_types.dingaudit.text': '钉钉文件审计',
  'audit.system.evt_types.device.text': '设备',
  'audit.system.evt_types.app_policy.text': '应用策略',
  'audit.system.evt_types.app_policy.subs.update': '应用策略更新',
  'audit.system.evt_types.access_policy.text': '设备准入策略',

  'audit.system.evt_types.dingaudit.subs.export': '导出',

  'audit.system.evt_types.user.subs.new': '创建用户',
  'audit.system.evt_types.user.subs.update': '编辑用户',
  'audit.system.evt_types.user.subs.delete': '删除用户',
  'audit.system.evt_types.user.subs.disable': '禁用用户',
  'audit.system.evt_types.user.subs.enable': '启用用户',
  'audit.system.evt_types.user.subs.csv_import': 'CSV批量导入',
  'audit.system.evt_types.user.subs.ad_ldap_import': 'AD/LDAP批量导入',
  'audit.system.evt_types.user.subs.reset_password': '重置密码',
  'audit.system.evt_types.user.subs.enable_user_cert': '启用证书',
  'audit.system.evt_types.user.subs.disable_user_cert': '禁用证书',
  'audit.system.evt_types.user.subs.delete_user_cert': '删除证书',
  'audit.system.evt_types.user.success.reset_password': '密码重置',
  'audit.system.evt_types.user.success.update': '用户信息更新成功',
  'audit.system.evt_types.user.success.new': '用户新增成功',
  'audit.system.evt_types.user_ext_attr.text': '用户扩展属性',
  'audit.system.evt_types.user_ext_attr.success.new': '新建用户扩展属性成功',
  'audit.system.evt_types.user_ext_attr.success.update': '修改用户扩展属性成功',
  'audit.system.evt_types.user_ext_attr.success.delete': '删除用户扩展属性成功',
  'audit.system.evt_types.user_ext_attr.subs.new': '创建用户扩展属性',
  'audit.system.evt_types.user_ext_attr.subs.update': '编辑用户扩展属性',
  'audit.system.evt_types.user_ext_attr.subs.delete': '删除用户扩展属性',
  'audit.system.evt_types.org_ext_attr.text': '组织扩展属性',
  'audit.system.evt_types.org_ext_attr.subs.new': '创建组扩展属性',
  'audit.system.evt_types.org_ext_attr.subs.update': '编辑组扩展属性',
  'audit.system.evt_types.org.success.update': '用户组更新成功',
  'audit.system.evt_types.org.success.new': '用户组新建成功',
  'audit.system.evt_types.tag.success.new': '用户标签创建成功',
  'audit.system.evt_types.tag.success.update': '用户标签更新成功',
  'audit.system.evt_types.tag.success.delete': '用户标签删除成功',
  'audit.system.evt_types.user.success.delete': '用户删除成功',
  'audit.system.evt_types.user.success.disable': '用户禁用成功',
  'audit.system.evt_types.app.success.new': '应用创建成功',
  'audit.system.evt_types.app.success.delete': '应用删除成功',
  'audit.system.evt_types.selfservice.success.change_password': '自服务修改密码成功',
  'audit.system.evt_types.selfservice.success.forget_password': '自服务忘记密码',
  new_user_role_binding: '用户绑定角色',
  delete_user_role_binding: '用户解绑角色',
  update: '更新',
  user_tag: '用户标签',
  create: '新建',
  new: '新建',
  delete: '删除',
  remove_user_tag: '删除标签',
  sync: '同步',
  reset_password: '密码重置',
  disable: '禁用',
  update_ds_config: '更新通讯录',
  delete_role: '删除角色',
  new_role: '新建角色',
  'sns config type invalid': '通讯录集成类型不合法',
  'Requst sync profile not found': '同步的证书未找到',
  'audit.system.evt_types.profile.text': '用户画像配置',
  'audit.system.evt_types.profile.subs.new': '创建用户画像配置',
  'audit.system.evt_types.profile.subs.update': '编辑用户画像配置',
  'audit.system.evt_types.profile.subs.delete': '删除用户画像配置',
  'audit.system.evt_types.login_policy.text': '应用认证策略',
  'audit.system.evt_types.login_policy.subs.new': '创建应用认证策略',
  'audit.system.evt_types.login_policy.subs.update': '编辑应用认证策略',
  'audit.system.evt_types.login_policy.subs.delete': '删除应用认证策略',
  'audit.system.evt_types.login_policy.subs.disable': '禁用应用认证策略',
  'audit.system.evt_types.login_policy.subs.enable': '启用应用认证策略',
  'audit.system.evt_types.login_policy.subs.set_as_default': '设置为默认应用认证策略',
  'audit.system.evt_types.login_policy.success.update': '应用认证策略更新成功',
  'audit.system.evt_types.login_policy.success.new': '应用认证策略新建成功',

  'audit.system.evt_types.auth_factor.text': '认证因子设置',
  'audit.system.evt_types.auth_factor.subs.update': '编辑认证因子',
  'audit.system.evt_types.auth_factor.subs.disable': '禁用认证因子',
  'audit.system.evt_types.auth_factor.subs.enable': '启用认证因子',
  // 'audit.system.evt_types.sns_config.text': '企业信息',
  'audit.system.evt_types.sns_config.text': '企业认证源',
  'audit.system.evt_types.sns_config.subs.new': '创建企业信息',
  'audit.system.evt_types.sns_config.subs.update': '编辑认证因子',
  'audit.system.evt_types.sns_config.subs.delete': '删除企业信息',
  'audit.system.evt_types.sns_config.subs.set_as_default': '设置为默认',
  'audit.system.evt_types.push_connector.text': '通讯录同步',
  'audit.system.evt_types.push_connector.subs.new': '创建用户同步',
  'audit.system.evt_types.push_connector.subs.update': '编辑用户同步',
  'audit.system.evt_types.push_connector.subs.delete': '删除用户同步',
  'audit.system.evt_types.push_connector.subs.disable': '禁用用户同步',
  'audit.system.evt_types.push_connector.subs.enable': '启用用户同步',
  'audit.system.evt_types.push_connector.subs.sync': '进行数据同步操作',
  'audit.system.evt_types.push_connector.success.sync': '进行数据同步操作成功',
  'audit.system.evt_types.push_connector.subs.batch_sync': '进行数据批量同步操作',
  'audit.system.evt_types.push_connector.success.update': '用户同步更新',
  'audit.system.evt_types.push_connector.success.disable': '用户同步禁用',
  'audit.system.evt_types.push_connector.success.new': '用户同步新建',
  'audit.system.evt_types.chat.text': '群聊',
  'audit.system.evt_types.chat.success.create': '创建群聊',
  'audit.system.evt_types.chart.subs.update': '编辑群聊',
  'audit.system.evt_types.chart.subs.delete': '删除群聊',
  'audit.system.evt_types.ds.text': '通讯录集成',
  'audit.system.evt_types.ds.subs.create_ds_config': '创建链接器',
  'audit.system.evt_types.ds.subs.update_ds_config': '编辑链接器',
  'audit.system.evt_types.ds.subs.delete_ds_config': '删除链接器',
  'audit.system.evt_types.ds.subs.disable_ds_config': '禁用链接器',
  'audit.system.evt_types.ds.subs.enable_ds_config': '启用链接器',
  'audit.system.evt_types.ds.success.create_ds_config': '创建链接器成功',
  'audit.system.evt_types.ds.success.update_ds_config': '编辑链接器成功',
  'audit.system.evt_types.ds.success.delete_ds_config': '删除链接器成功',
  'audit.system.evt_types.sns_config.success.delete': '删除链接器成功',
  'audit.system.evt_types.sns_config.success.update': '编辑链接器成功',
  // 'audit.system.evt_types.org.text': '组织结构',
  'audit.system.evt_types.org.subs.new': '创建组织结构',
  'audit.system.evt_types.org.subs.update': '编辑组织结构',
  'audit.system.evt_types.org.subs.delete': '删除组织结构',
  'audit.system.evt_types.org.subs.join_org': '加入组织结构',
  'audit.system.evt_types.org.subs.leave_org': '离开组织结构',
  'audit.system.evt_types.org.subs.move_org': '移动组织结构',
  'audit.system.evt_types.tag.text': '标签',
  'audit.system.evt_types.tag.subs.new': '创建标签',
  'audit.system.evt_types.tag.subs.update': '编辑标签',
  'audit.system.evt_types.tag.subs.delete': '删除标签',
  'audit.system.evt_types.tag.subs.user_tag': '给用户打标签',
  'audit.system.evt_types.tag.subs.remove_user_tag': '移除用户标签',
  'audit.system.evt_types.tag.success.user_tag': '给用户__usernames__打标签',
  'audit.system.evt_types.tag.success.remove_user_tag': '移除用户__usernames__标签',
  'audit.system.evt_types.app.text': '应用',
  'audit.system.evt_types.app.subs.new': '添加应用',
  'audit.system.evt_types.app.subs.update': '编辑应用',
  'audit.system.evt_types.app.subs.delete': '删除应用',
  'audit.system.evt_types.app.subs.disable': '禁用应用',
  'audit.system.evt_types.app.subs.enable': '启用应用',
  'audit.system.evt_types.app.subs.new_or_update': '应用配置',
  'audit.system.evt_types.app.subs.rotate_client_secret': '更新Client Secret',
  'audit.system.evt_types.app.subs.user_entitlement': '用户授权',
  'audit.system.evt_types.app.subs.revoke_user_entitlement': '取消用户授权',
  'audit.system.evt_types.app.subs.org_entitlement': '组织结构授权',
  'audit.system.evt_types.app.subs.revoke_org_entitlement': '取消组织结构授权',
  'audit.system.evt_types.app.subs.tag_entitlement': '标签授权',
  'audit.system.evt_types.app.subs.revoke_tag_entitlement': '取消标签授权',
  'audit.system.evt_types.app.subs.new_permission': '新建权限',
  'audit.system.evt_types.app.subs.update_permission': '更新权限',
  'audit.system.evt_types.app.subs.delete_permission': '删除权限',
  'audit.system.evt_types.app.subs.new_permission_set': '新建权限组',
  'audit.system.evt_types.app.subs.update_permission_set': '更新权限组',
  'audit.system.evt_types.app.subs.delete_permission_set': '删除权限组',
  'audit.system.evt_types.app.subs.new_role': '新建角色',
  'audit.system.evt_types.app.subs.update_role': '更新角色',
  'audit.system.evt_types.app.subs.delete_role': '删除角色',
  'audit.system.evt_types.app.subs.new_user_role_binding': '给用户分配角色',
  'audit.system.evt_types.app.subs.update_user_role_binding': '更新用户角色',
  'audit.system.evt_types.app.subs.delete_user_role_binding': '删除用户角色',
  'audit.system.evt_types.app.subs.new_org_role_binding': '给组织结构分配角色',
  'audit.system.evt_types.app.subs.update_org_role_binding': '更新组织结构角色',
  'audit.system.evt_types.app.subs.delete_org_role_binding': '删除组织结构角色',
  'audit.system.evt_types.app.subs.new_tag_role_binding': '给标签分配角色',
  'audit.system.evt_types.app.subs.update_tag_role_binding': '更新标签角色',
  'audit.system.evt_types.app.subs.delete_tag_role_binding': '删除标签角色',
  'audit.system.evt_types.app.subs.update_app_login_policy': '更新应用应用认证策略',
  'audit.system.evt_types.app.subs.rotate_client_signature_secret': '重新生成签名对称秘钥',
  'audit.system.evt_types.app.success.update': '应用更新成功',
  'audit.system.evt_types.app.success.new_permission': '__name__',
  'audit.system.evt_types.app.success.update_permission': '__name__',
  'audit.system.evt_types.app.success.delete_permission': '__name__',
  'audit.system.evt_types.app.success.new_role': '__name__',
  'audit.system.evt_types.app.success.update_role': '__name__',
  'audit.system.evt_types.app.success.delete_role': '__name__',
  'audit.system.evt_types.app.success.new_user_role_binding':
    '给用户__targets__分配角色__role_name__',
  'audit.system.evt_types.app.success.update_user_role_binding':
    '更新用户__target__角色__role_name__',
  'audit.system.evt_types.app.success.delete_user_role_binding':
    '删除用户__targets__角色__role_name__',
  'audit.system.evt_types.app.success.new_org_role_binding':
    '给组织结构__targets__分配角色__role_name__',
  'audit.system.evt_types.app.success.update_org_role_binding':
    '更新组织结构__target__角色__role_name__',
  'audit.system.evt_types.app.success.delete_org_role_binding':
    '删除组织结构__targets__角色__role_name__',
  'audit.system.evt_types.app.success.new_tag_role_binding':
    '给标签__targets__分配角色__role_name__',
  'audit.system.evt_types.app.success.update_tag_role_binding':
    '更新标签__target__角色__role_name__',
  'audit.system.evt_types.app.success.delete_tag_role_binding':
    '删除标签__targets__角色__role_name__',
  'audit.system.evt_types.selfservice.text': '自服务',
  'audit.system.evt_types.selfservice.subs.self_user_register': '自注册',
  'audit.system.evt_types.selfservice.subs.forget_password': '忘记密码',
  'audit.system.evt_types.selfservice.subs.self_reset_password': '重置密码',
  'audit.system.evt_types.selfservice.subs.change_password': '修改密码',
  'audit.system.evt_types.selfservice.subs.update_userinfo': '更新个人信息',
  'audit.system.evt_types.selfservice.subs.revoke_app_auth': '取消应用授权',
  'audit.system.evt_types.selfservice.subs.verify_email_request': '请求验证邮箱',
  'audit.system.evt_types.selfservice.subs.verify_email': '绑定邮箱',
  'audit.system.evt_types.selfservice.subs.verify_mobile_code_request': '验证手机号请求',
  'audit.system.evt_types.selfservice.subs.verify_mobile': '验证手机号',
  'audit.system.evt_types.selfservice.subs.forget_password_mobile_code_request': '通过手机忘记密码',
  'audit.system.evt_types.selfservice.subs.request_cert_activecode': '申请证书上传码',
  'audit.system.evt_types.selfservice.subs.upload_cert': '上传证书',
  'audit.system.evt_types.selfservice.subs.delete_cert': '删除证书',
  'audit.system.evt_types.selfservice.subs.disable_cert': '禁用证书',
  'audit.system.evt_types.selfservice.subs.enable_cert': '启用证书',
  'audit.system.evt_types.selfservice.subs.get_onetime_password': '获取一次性登录码',
  'audit.system.evt_types.selfservice.subs.revoke_app_single_token': '吊销登录凭证',
  'audit.system.evt_types.selfservice.subs.revoke_app_token': '吊销应用所有登录凭证',
  'audit.system.evt_types.selfservice.subs.revoke_app_other_token': '吊销应用所有其它登录凭证',
  'audit.system.evt_types.selfservice.subs.revoke_user_token': '吊销用户所有登录凭证',
  'audit.system.evt_types.setting.text': '系统设置',
  'audit.system.evt_types.setting.success.update_all_policy': '系统配置-更新策略成功',
  'audit.system.evt_types.setting.subs.update_smtp': '更新邮件服务器',
  'audit.system.evt_types.setting.subs.update_password_policy': '更新密码策略',
  'audit.system.evt_types.setting.subs.update_login_policy': '更新应用认证策略',
  'audit.system.evt_types.setting.subs.update_sms': '更新短信网关配置',
  'audit.system.evt_types.setting.subs.update_all_policy': '更新系统设置',
  'audit.system.evt_types.setting.subs.update_template': '更新模版信息',
  'audit.system.evt_types.sync.text': '系统同步',
  'audit.system.evt_types.sync.subs.change_password': '用户更新密码',
  'audit.system.evt_types.login.text': '登录',
  'audit.system.evt_types.login.subs.signin': '登录',
  'audit.system.evt_types.login.subs.signout': '退出登录',
  'audit.system.evt_types.login.subs.authorize': '授权',
  'audit.system.evt_types.login.subs.authorize_token': '授权令牌',
  'audit.system.evt_types.login.subs.authorize_qrcode': '授权二维码',
  'audit.system.evt_types.login.success.authorize_token': '__name__',
  'news.news': '消息中心',
  'news.pushHistory': '推送历史',
  'news.newsInfo': '消息详情',
  'news.basicInfo': '基本信息',
  'news.pushDetails': '推送详情',
  'news.sendTime': '发送时间',
  'news.sendStatus': '发送状态',
  'news.newsContent': '消息内容',
  'news.newsType': '消息类型',
  'news.noticeType': '通知类型',
  'news.linkUrl': '链接地址',
  'news.picUrl': '图片地址',
  'news.linkTitle': '链接标题',
  'news.linkDesc': '链接描述',
  'news.picName': '图片名称',
  'news.fileID': '文件ID',
  'news.invalidUserID': '无效用户ID',
  'news.outBoundsNotSent': '越界未发送的用户',
  'news.sendFail': '发送失败的用户',
  'news.readMessage': '已读消息的用户',
  'news.unreadMessages': '未读消息的用户',
  'news.searchCon': '搜索内容',
  'news.send': '已发送',
  'news.sending': '发送中',
  'news.text': '文本',
  'news.link': '链接',
  'news.image': '图片',
  'news.voice': '语音',
  'news.file': '文件',
  'news.card': '卡片',
  'news.work': '待办任务',
  'news.workNotice': '工作台通知',
  'news.untreated': '未处理',
  'news.processing': '处理中',
  'news.finished': '处理完毕',
  'news.fail': '发送失败',
  'news.result': '结果',
  'news.success': '发送成功',
  'news.invalid': '无效用户',
  'news.forbidden': '禁止发送',
  'news.failure': '失败',
  'news.orgID': '组ID',
  'news.name': '名称',
  'news.parentOrg': '父组织机构',
  'news.choose': '筛选',
  'user.attr.username': '用户名',
  'user.attr.nickname': '昵称',
  'user.attr.first_name': '名',
  'user.attr.last_name': '姓',
  'user.attr.email': '邮箱',
  'user.attr.name': '姓名',
  'user.attr.preferred_username': '别名',
  'user.attr.phone_number': '手机号',
  'user.attr.employee_number': '员工编号',
  'user.attr.gender': '性别',
  'user.attr.address': '地址',
  'user.attr.birthdate': '生日',
  'user.attr.cost_center': '结算中心',
  'user.attr.division': '分支结构',
  'user.attr.telephone_number': '座机号',
  'user.attr.title': '职位',
  'user.attr.user_code': '职位编号',
  'user.attr.manager': '上级经理',
  'user.attr.locale': '工作地',
  'user.attr.website': '个人主页',
  'user.attr.zoneinfo': '时区',
  'user.attr.sort_order': '排序号',
  'user.attr.picture': '头像',
  'user.attr.cert': '用户证书',
  'user.attr.tag': '静态标签',
  'user.attr.group': '组织结构',
  'user.attr.sub': '用户ID',
  'user.attr.type': '用户类型',
  'user.attr.email_verified': '邮箱已校验',
  'user.attr.phone_number_verified': '手机已校验',
  'user.attr.dynamic_tag': '动态标签',
  'user.attr.last_login': '上次登录时间',
  'user.attr.login_geo': '上次登录地理位置',
  'user.attr.profile': '用户资料URL',
  'user.attr.pwd_changed_time': '密码修改时间',
  'user.attr.pwd_expiration_time': '密码过期时间',
  'user.attr.created_mode': '创建模式',
  'user.attr.created_by': '创建者',
  'user.attr.created_at': '创建时间',
  'user.attr.updated_by': '更新者',
  'user.attr.updated_at': '更新时间',
  'user.attr.create_by': '创建者',
  'user.attr.create_time': '创建时间',
  'user.attr.update_by': '更新者',
  'user.attr.update_time': '更新时间',
  'user.attr.status': '状态',
  'user.attr.org_ids': '部门IDs',
  'user.attr.connector_type': '链接器类型',
  'user.attr.group_positions': '职位',
  'user.attr.come_from': '地址',
  'user.attr.password_status': '密码状态',
  'user.attr.pwd_expired_time': '密码过期时间',
  'user.attr.connector_uid': '链接器id',
  'user.attr.connector_batch_no': '链接器批次编号',
  'user.attr.connector_sub_id': '链接器子id',
  'user.attr.checksum': '检查合计',
  'user.attr.tenant_id': '租户id',
  'user.attr.password': '用户密码',
  'user.attr.user_job_number': '工号',
  'user.attr.user_extension': '用户扩展信息',
  'user.attr.org_extension': '机构扩展信息',
  'user.attr.start_date': '有效起始日期',
  'user.attr.end_date': '到期日期',
  'user.attr.apply_explain': '申请说明',
  'org.attr.name': '用户组名称',
  'org.attr.status': '用户组状态',
  'org.attr.description': '用户组描述',
  'org.attr.distinguishedName': '用户组唯一标示',
  'org.attr.parentRefId': '上级关联id',
  'org.attr.org_path': '用户组路径',
  'org.attr.seq': '用户组序号',
  'org.attr.id': '用户组id',
  'Permission Set not found': '权限不存在',
  forget_password: '忘记密码',
  update_all_policy: '更新策略',
  new_role: '角色创建',
  create_ds_config: '连接器创建',
  update_ds_config: '编辑链接器',
};
