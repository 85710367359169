const allPath = [
  {
    path: '/dingding',
    layout: false,
    component: './Account/Login/Dingding',
  },
  {
    path: '/user',
    layout: false,
    routes: [
      {
        path: '/user',
        routes: [
          {
            name: 'login',
            path: '/user/login',
            component: './Account/Login',
          },
          {
            name: 'login',
            path: '/user/login1',
            component: './Account/Login',
          },
          {
            name: 'resetPassword',
            path: '/user/resetPassword',
            component: './UserCenter/UserManagement/ResetPasswordPage',
          },
        ],
      },
    ],
  },
  {
    name: 'personPortal',
    path: '/portal',
    component: './Person',
    layout: false,
    hideInMenu: true,
  },
  {
    name: 'users',
    icon: 'icon-user',
    path: '/uc',
    routes: [
      {
        path: '/uc',
        redirect: '/uc/users',
      },
      {
        name: 'management',
        path: '/uc/users',
        component: './UserCenter/UserManagement',
      },
      {
        name: 'userTag',
        path: '/uc/userTag',
        component: './UserCenter/Tag/TagList',
      },
      {
        name: 'editTagDynamic',
        path: '/uc/userTag/editDynamic',
        component: './UserCenter/Tag/EditTagDynamic',
        hideInMenu: true,
      },
      {
        name: 'editTag',
        path: '/uc/userTag/edit',
        component: './UserCenter/Tag/EditTag',
        hideInMenu: true,
      },
      {
        name: 'add',
        path: '/uc/users/add',
        component: './UserCenter/UserManagement/CreateOrEdit',
        hideInMenu: true,
      },
      {
        name: 'profile',
        path: '/uc/usersDetail',
        component: './UserCenter/UserManagement/UserProfile',
        hideInMenu: true,
      },
      {
        name: 'selfInfo',
        path: '/uc/selfInfo',
        component: './UserCenter/UserManagement/UserProfile/Person',
        layout: false,
        hideInMenu: true,
      },

      {
        name: 'userInfoLs',
        path: '/uc/userInfoLs',
        component: './UserCenter/UserManagement/AppCenter/userInfo',
        layout: false,
        hideInMenu: true,
      },

      {
        name: 'edit',
        path: '/uc/users/:username/edit',
        component: './UserCenter/UserManagement/CreateOrEdit',
        hideInMenu: true,
      },
      {
        name: 'linker',
        path: '/uc/linker',
        component: './UserCenter/Linker',
      },
      {
        name: 'linker.add',
        path: '/uc/linker/add',
        component: './UserCenter/Linker/CreateOrEdit',
        hideInMenu: true,
      },
      {
        name: 'linker.edit',
        path: '/uc/linkerDetail',
        component: './UserCenter/Linker/CreateOrEdit',
        hideInMenu: true,
      },
      {
        name: 'syncgene',
        path: '/uc/syncgene',
        component: './UserCenter/SyncGene',
      },
      {
        name: 'syncgene.add',
        path: '/uc/syncgene/add',
        component: './UserCenter/SyncGene/CreateOrEdit',
        hideInMenu: true,
      },
      {
        name: 'syncgene.edit',
        path: '/uc/syncgeneDetail',
        component: './UserCenter/SyncGene/CreateOrEdit',
        hideInMenu: true,
      },
      {
        name: 'ladpService',
        path: '/uc/ladpService',
        component: './UserCenter/LadpService',
      },
      {
        name: 'userApplyfor',
        path: '/uc/userapplyfor',
        layout: false,
        component: './UserCenter/Applyfor',
        hideInMenu: true,
      },
      {
        name: 'userApplyforControl',
        path: '/uc/userapplyforControl',
        layout: false,
        component: './UserCenter/Applyfor/mainControl',
        hideInMenu: true,
      },
      {
        name: 'userApplyforRenewal',
        path: '/uc/userApplyforRenewal',
        layout: false,
        component: './UserCenter/Applyfor/renewal',
        hideInMenu: true,
      },
      {
        name: 'userApplyAdd',
        path: '/uc/userapplyAdd',
        layout: false,
        component: './UserCenter/Applyfor/addApply',
        hideInMenu: true,
      },
      {
        name: 'userDetilsInfo',
        path: '/uc/userdetilsInfo',
        layout: false,
        component: './UserCenter/Applyfor/userInfo',
        hideInMenu: true,
      },
      {
        name: 'expireUser',
        path: '/uc/expireUser',
        layout: false,
        component: './UserCenter/ExpireUser',
        hideInMenu: true,
      },
      {
        name: 'expireUserDetail',
        path: '/uc/expireUserDetail',
        layout: false,
        component: './UserCenter/ExpireUserDetail',
        hideInMenu: true,
      },
      {
        name: 'importResult',
        path: '/uc/importResult',
        layout: false,
        component: './UserCenter/UserManagement/ImportResult',
        hideInMenu: true,
      },
    ],
  },
  {
    name: 'app',
    icon: 'icon-app',
    path: '/apps',
    routes: [
      {
        path: '/apps',
        redirect: '/apps/list',
      },
      {
        name: 'list',
        path: '/apps/list',
        component: './AppCenter/AppList',
      },
      {
        name: 'list.view',
        path: '/apps/appDetail',
        component: './AppCenter/AppList/AppDetails',
        hideInMenu: true,
      },
      {
        name: 'list.edit',
        path: '/apps/appEdit',
        component: './AppCenter/AppList/EditApp',
        hideInMenu: true,
      },
      {
        name: 'list.add',
        path: '/apps/appAdd',
        component: './AppCenter/AppList/EditApp',
        hideInMenu: true,
      },
      {
        name: 'list.market',
        path: '/apps/applicMaket',
        component: './AppCenter/AppMarket',
        hideInMenu: true,
      },
      {
        name: 'list.Details',
        path: '/apps/applicMaket/applicDetails',
        component: './AppCenter/AppMarket/ApplicationDetails/index',
        hideInMenu: true,
      },
      {
        name: 'list.Configuration',
        path: '/apps/applicMaket/applicConfig',
        component: './AppCenter/AppMarket/AppConfig',
        hideInMenu: true,
      },
      {
        name: 'abmapps',
        path: '/apps/abmapps',
        component: './AppCenter/AbmApps',
      },
      {
        name: 'entapps',
        path: '/apps/entapps',
        component: './AppCenter/EnterpriseApps',
      },
      {
        name: 'corpcert',
        path: '/apps/corpcert',
        component: './CorpCert',
      },
      // 创建分类
      {
        name: 'createClassification',
        path: '/apps/createClassification',
        component: './AppCenter/Classification',
        hideInMenu: true,
      },
    ],
  },
  {
    name: 'devices',
    icon: 'icon-device',
    path: '/devices',
    routes: [
      {
        path: '/devices',
        redirect: '/devices/list',
      },
      {
        name: 'list',
        path: '/devices/list',
        component: './DevicesCenter/DevicesList',
      },
    ],
  },
  {
    name: 'nofunc',
    path: '/nofunc',
    hideInMenu: true,
    component: './noFunc/index',
  },
  {
    name: 'policy',
    icon: 'icon-policy',
    path: '/policy',
    routes: [
      {
        path: '/policy',
        redirect: '/policy/appPolicy',
      },
      {
        name: 'appPolicy',
        path: '/policy/appPolicy',
        component: './PolicyCenter/index',
      },
      {
        name: 'appPolicy.editPolicy',
        path: '/policy/appPolicy/editPolicy',
        component: './PolicyCenter/EditAppPolicy/index',
        hideInMenu: true,
      },
      {
        name: 'deviceAdmitPolicy.editPolicy',
        path: '/policy/deviceAdmitPolicy/editPolicy',
        component: './PolicyCenter/EditDevicePolicy/index',
        hideInMenu: true,
      },
      {
        name: 'deviceAdmitPolicy',
        path: '/policy/deviceAdmitPolicy',
        component: './PolicyCenter/index',
      },
    ],
  },
  {
    name: 'newsList',
    icon: 'icon-msg',
    path: '/newsList',
    routes: [
      {
        path: '/newsList',
        redirect: '/newsList/newsList',
      },
      {
        name: 'newsList',
        path: '/newsList/newsList',
        component: './NewsList',
      },
      {
        name: 'newsDetail',
        path: '/newsList/newsList/newsDetail',
        component: './NewsList/NewsDetail',
        hideInMenu: true,
      },
    ],
  },
  {
    name: 'fileAudit',
    icon: 'icon-fileAudit',
    path: '/fileAudit',
    routes: [
      {
        path: '/fileAudit',
        redirect: '/fileAudit/fileAuditLog',
      },
      {
        name: 'fileAuditLog',
        path: '/fileAudit/fileAuditLog',
        component: './FileAuditLog',
      },
    ],
  },
  {
    name: 'log',
    icon: 'icon-app',
    path: '/log',
    routes: [
      {
        path: '/log',
        redirect: '/log/loginLog',
      },
      {
        name: 'loginLog',
        path: '/log/loginLog',
        component: './LogAudit/LoginLog',
      },
      {
        name: 'systemLog',
        path: '/log/systemLog',
        component: './LogAudit/SystemLog',
      },
    ],
  },
  {
    name: 'permissions',
    icon: 'icon-auth',
    path: '/permissions',
    routes: [
      {
        path: '/permissions',
        redirect: '/permissions/users',
      },
      {
        name: 'users',
        path: '/permissions/users',
        component: './Permissions/Users',
      },
      {
        name: 'usersDetail',
        path: '/permissions/users/detail',
        component: './UserCenter/UserManagement/UserProfile',
        hideInMenu: true,
      },
      {
        name: 'roles',
        path: '/permissions/roles',
        component: './Permissions/Roles',
      },
    ],
  },
  {
    name: 'setting',
    icon: 'icon-setting',
    path: '/setting',
    routes: [
      {
        path: '/setting',
        redirect: '/setting/editSetting',
      },
      // {
      //   name: 'editEnt',
      //   path: '/setting/editEnt',
      //   component: './EditEnt/index',
      // },
      {
        name: 'editSetting',
        path: '/setting/editSetting',
        component: './EditSetting/index',
      },
      {
        name: 'templates',
        path: '/setting/templates',
        component: './MsgTemplate/index',
      },
      {
        name: 'licencePage',
        path: '/setting/licencePage',
        component: './LicencePage/index',
      },

      {
        name: 'invitations',
        path: '/setting/invitations',
        component: './AppCenter/AppInvitations',
      },
      {
        name: 'customize',
        path: '/setting/customize',
        component: './AppCenter/CustomizeSettings',
      },
    ],
  },
  {
    name: 'noPermissise',
    path: '/noPermissise',
    hideInMenu: true,
    component: './Nopromise',
  },
  {
    component: './404',
  },
];

export default allPath;
