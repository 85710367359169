import staticMethod from '@/utils/staticMethod';
import { history } from 'umi';
import request from 'umi-request';
import RLSafeBase64 from 'urlsafe-base64';

let ucSsoUrl: string, responseType: string, clientId: string, redirectUri: string, scope: string;

export const setAuthData = (options: any) => {
  ucSsoUrl = options.ucSsoUrl;
  responseType = options.responseType || 'token';
  clientId = options.clientId;
  redirectUri = options.redirectUri;
  scope = options.scope;
};
export const authorize = (beforeAuthorizeCb?: any) => {
  const state = new Date().getTime();
  let params = {
    response_type: responseType,
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: scope,
    state: state,
    // access_token:
  };
  const oldState = sessionStorage.getItem('state');
  console.log("sessionStorage.getItem('state')", oldState);
  if (!oldState) {
    sessionStorage.setItem('state', state.toString());
    staticMethod.clearWebAuth();
    if (typeof beforeAuthorizeCb === 'function') {
      beforeAuthorizeCb();
    }
    staticMethod.parseQueryString(window.location.href, function (queryObj: any) {
      params = Object.assign(queryObj, params);
      // const redirectUriStr = sessionStorage.getItem('redirect_uri');
      // if (redirectUriStr) {
      params.redirect_uri = window.location.href;
      // }
    });
    params['access_token'] = null;
    params['token_type'] = null;
    params['expires_in'] = null;
    params['id_token'] = null;
    // delete params?.access_token
    console.log('authorize:' + ucSsoUrl);
    location.href = ucSsoUrl + '/authorize' + staticMethod.createQueryString(params);
  }
  // console.log('authorize:' + ucSsoUrl);
  // location.href = ucSsoUrl + '/authorize' + staticMethod.createQueryString(params);
};
export const initWebAuth = function (successCb: any, failureCb: any) {
  staticMethod.parseQueryString(window.location.href, function (queryObj: any) {
    let accessToken, idToken, accessTokenReceivedAt, accessTokenNow, expiresIn;
    accessToken = queryObj.access_token;
    const state = queryObj.state;
    const localState = sessionStorage.getItem('state');
    console.log('sessionStorage.removeItem');
    sessionStorage.removeItem('state');

    console.log('initWebAuth data:' + JSON.stringify(queryObj));
    if (accessToken) {
      if (!localState || localState === state) {
        idToken = queryObj.id_token;
        expiresIn = parseInt(queryObj.expires_in, 10) * 1000;
        accessTokenReceivedAt = new Date().getTime();
        sessionStorage.setItem('access_token', accessToken);
        sessionStorage.setItem('id_token', idToken);
        sessionStorage.setItem('expires_in', expiresIn.toString());
        sessionStorage.setItem('access_token_received_at', accessTokenReceivedAt.toString());
        if (typeof successCb === 'function') {
          successCb(accessToken, idToken, accessTokenReceivedAt, expiresIn);
        }
      } else {
        if (typeof failureCb === 'function') {
          failureCb('invalid_state', 'invalid_state');
        }
      }
    } else {
      accessToken = sessionStorage.getItem('access_token');
      if (accessToken) {
        expiresIn = parseInt(sessionStorage.getItem('expires_in') || '0', 10);
        accessTokenReceivedAt = parseInt(
          sessionStorage.getItem('access_token_received_at') || '0',
          10,
        );
        idToken = sessionStorage.getItem('id_token');
        accessTokenNow = new Date().getTime();
        if (!idToken || accessTokenReceivedAt + expiresIn < accessTokenNow) {
          //失效
          if (typeof failureCb === 'function') {
            failureCb('access_token_expire', 'access_token_expire');
          }
          authorize();
        } else {
          if (typeof successCb === 'function') {
            successCb(accessToken, idToken, accessTokenReceivedAt, expiresIn);
          }
        }
      } else {
        if (typeof failureCb === 'function') {
          failureCb('no_access_token', 'no_access_token');
        }
        if (!queryObj.error) {
          authorize();
        }
      }
    }
  });
};
export const loginSuccess = function (accessToken: string, tcode: string, callback: any) {
  sessionStorage.setItem(
    'reduxPersist:login',
    JSON.stringify({
      loggedIn: true,
    }),
  );
  request.interceptors.request.use((url, options) => {
    const authHeader = { Authorization: `Bearer ${accessToken}`, tcode };
    if (options.headers && !options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json';
    }
    if (url.includes('configs/policies') && options.method === 'get') {
      delete authHeader.Authorization;
    }
    return {
      url: url,
      options: { ...options, interceptors: true, headers: authHeader },
    };
  });
  if (typeof callback === 'function') {
    callback();
  }
};
export const noLogin = () => {
  return (
    //下列页面不需要授权就可以访问
    window.location.href.indexOf('register') !== -1 ||
    window.location.href.indexOf('mailSent') !== -1 ||
    window.location.href.indexOf('smsSent') !== -1 ||
    window.location.href.indexOf('dingding') !== -1 ||
    window.location.href.indexOf('resetPassword') !== -1 ||
    window.location.href.indexOf('forget_password') !== -1 ||
    window.location.href.indexOf('forcedUpdatePwd') !== -1 ||
    window.location.href.indexOf('verify_email_address') !== -1 ||
    window.location.href.indexOf('#/cert') !== -1 ||
    window.location.href.indexOf('#/email') !== -1 ||
    window.location.href.indexOf('#/sms') !== -1 ||
    window.location.pathname === '/user/login' ||
    window.location.pathname === '/user/login/'
  );
};
//是否已经auth了
//是否已经auth了
export const isAuthed = () => {
  return (
    //下列页面不需要授权就可以访问
    window.location.href.indexOf('register') !== -1 ||
    window.location.href.indexOf('mailSent') !== -1 ||
    window.location.href.indexOf('smsSent') !== -1 ||
    window.location.href.indexOf('forget_password') !== -1 ||
    window.location.href.indexOf('resetPassword') !== -1 ||
    window.location.href.indexOf('verify_email_address') !== -1 ||
    window.location.href.indexOf('#/cert') !== -1 ||
    window.location.href.indexOf('#/email') !== -1 ||
    window.location.href.indexOf('#/sms') !== -1 ||
    window.location.pathname === '/user/login' ||
    window.location.pathname === '/user/login/' ||
    (window.location.href.indexOf('/dingding') !== -1 &&
      window.location.href.indexOf('continue') !== -1) || //请求dingding登录页面，并且已经包含了continue页面，则不发起授权请求
    (window.location.href.indexOf('#login') !== -1 &&
      window.location.href.indexOf('continue') !== -1) //请求login登录页面，并且已经包含了continue页面，则不发起授权请求
  );
};
export const startInitAuth = (callback?: any) => {
  initWebAuth(
    (accessToken: any) => {
      const tmpAccessToken = sessionStorage.getItem('id_token'),
        tokenInfo = JSON.parse(
          RLSafeBase64.decode(
            tmpAccessToken && tmpAccessToken.split('.').length && tmpAccessToken.split('.')[1],
          ),
        );
      loginSuccess(accessToken, tokenInfo.tid, callback);
    },
    (errorType: any, errorDescription: any) => {
      if (errorType === 'invalid_state') {
        history.replace('/authorizationError/' + errorType + '/' + errorDescription);
      }
      sessionStorage.clear();
    },
  );
};
