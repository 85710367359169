import Footer from '@/components/Footer';
import RightContent from '@/components/RightContent';
import { hasFunc, parseQueryString, setLicData } from '@/utils/common.utils';
import { getPermMenuData } from '@/utils/configs.utils';
import * as WebAuth from '@/utils/webAuth';
import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import { notification } from 'antd';
import type { RequestConfig, RunTimeLayoutConfig } from 'umi';
import { history } from 'umi';

import { getPolicies } from '@/pages/EditSetting/service';
import { tenantLicense } from '@/pages/LicencePage/service';
import staticMethod from '@/utils/staticMethod';
import { permPaths } from '../config/menuData';
import { currentUser as queryCurrentUser } from './services/digitalsee/api';

const loginPath = ['/user/login/', '/user/login'];

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: DIGITALSEE.CurrentUser;
  licData?: DIGITALSEE.LicData;
  fetchUserInfo?: () => Promise<DIGITALSEE.CurrentUser | undefined>;
  fetchTenantLicense?: () => Promise<DIGITALSEE.LicData | undefined>;
  getPolicyData?: () => Promise<API.ResponseData | undefined>;
  policyData: API.ResponseData;
}> {
  const query: any = staticMethod.parseQueryString(window.location.href);
  const access_token: any = query && query?.access_token; // undefined

  const lastToken = sessionStorage.getItem('access_token'); // null

  if (!lastToken && !access_token && !WebAuth.isAuthed()) {
    WebAuth.startInitAuth();
    return {};
  } else {
    if (access_token) {
      sessionStorage.setItem('access_token', access_token);
    }
  }
  if (query.client_id) {
    sessionStorage.setItem('client_id', query.client_id || '');
  }
  if (query.continue) {
    sessionStorage.setItem('continue', query.continue || '');
  }
  if (query.redirect_uri) {
    sessionStorage.setItem('redirect_uri', query.redirect_uri || '');
  }
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      return msg.data;
    } catch (error) {}
    return undefined;
  };
  const getPolicieData = async () => {
    try {
      const result = await getPolicies();
      return result.data;
    } catch (error) {}
    return undefined;
  };

  const fetchTenantLicense = async () => {
    try {
      const msg = await tenantLicense();
      sessionStorage.setItem('licConfig', JSON.stringify(msg.data));
      setLicData(msg.data.license_config);
      return msg.data;
    } catch (error) {}
    return undefined;
  };
  const token = sessionStorage.getItem('access_token');
  const policyData = await getPolicieData();
  sessionStorage.setItem('ucName', policyData?.uc_name);
  // 如果是登录页面，不执行
  if (
    !loginPath.includes(history.location.pathname) &&
    !history.location.pathname.includes('authorize') &&
    token
  ) {
    const currentUser: any = await fetchUserInfo();
    const licData = currentUser && (await fetchTenantLicense());

    sessionStorage.setItem('permMenus', JSON.stringify(currentUser));
    const menuData = getPermMenuData(
      (currentUser && currentUser.permissions_sets) || [],
      currentUser.username || '',
    );
    return {
      fetchUserInfo,
      currentUser,
      menuData,
      licData,
      policyData,
      settings: {},
    };
  }

  return {
    fetchUserInfo,
    fetchTenantLicense,
    getPolicieData,
    settings: {},
  };
}

export const request: RequestConfig = {
  errorHandler: (error: any) => {
    const { response } = error;

    if (!response) {
      notification.error({
        description: '您的网络发生异常，无法连接服务器',
        message: '网络异常',
      });
    }
    throw error;
  },
};
// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  const userInfo = initialState?.currentUser;
  const client_id = sessionStorage.getItem('client_id');

  const isSelf = client_id === 'portal';
  // (userInfo && (!userInfo.permissions_sets || !userInfo.permissions_sets.length));

  const siderWidth =
    (isSelf && {
      siderWidth: 0,
    }) ||
    {};

  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    title: initialState?.policyData?.uc_name || '零信任安全管理系统',
    onPageChange: () => {
      const { location } = history;
      if (
        location.pathname === '/uc/selfInfo' ||
        location.pathname === '/uc/userapplyforControl' ||
        location.pathname === '/uc/selfInfo/' ||
        location.pathname === '/uc/userapplyforControl/' ||
        location.pathname === '/portal/' ||
        location.pathname === '/portal'
      ) {
        sessionStorage.setItem('client_id', 'portal');
      }
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && !WebAuth.noLogin()) {
        history.push(loginPath[0] + location.search);
        // WebAuth.authorize();
        return;
      }

      // if (location.pathname === '/uc/selfInfo' || location.pathname === '/uc/userapplyforControl') {
      //   sessionStorage.setItem('client_id', 'portal');
      // }
      //todo
      if (
        location.pathname === '/user/login1' ||
        location.pathname === '/nofunc' ||
        location.pathname.includes('resetPassword') ||
        location.pathname === '/uc/userInfoLs' ||
        location.pathname === '/uc/userdetilsInfo' ||
        location.pathname === '/uc/userapplyforControl/' ||
        location.pathname === '/uc/userapplyforControl' ||
        location.pathname === '/uc/userapplyAdd' ||
        location.pathname === '/uc/userApplyforRenewal' ||
        location.pathname === '/portal' ||
        location.pathname === '/portal/' ||
        location.pathname === '/uc/userapplyfor' ||
        location.pathname === '/uc/userapplyfor/' ||
        location.pathname === '/noPermissise' ||
        location.pathname === '/noPermissise/'
      ) {
        return;
      }

      if (
        !isSelf &&
        userInfo &&
        (!userInfo.permissions_sets || !userInfo.permissions_sets.length)
      ) {
        sessionStorage.setItem('client_id', 'portal');
        history.push('/noPermissise');

        // history.push(
        //   `/iam/authorize/?response_type=token&client_id=portal&redirect_uri=${window.location.origin}/portal/`
        // )
        // window.location.href = conf.getBackendUrl() +
        //   `/authorize/?response_type=token&client_id=portal&redirect_uri=${window.location.origin}/portal/`;
        return;
      }

      if (isSelf && userInfo) {
        // history.push(`/uc/selfInfo?username=${userInfo.username}`);
        history.push(`/uc/selfInfo`);
        return;
      }

      const has = hasFunc(location.pathname);
      if (!has) {
        history.replace('/nofunc');
        return;
      }
      if (location.pathname === '/user/login' || location.pathname === '/user/login/') {
        return;
      }

      if (location.pathname === '/') {
        const obj = parseQueryString(window.location.href);
        if (obj.continue) {
          history.push('/user/login' + location.search);
          return;
        }
        sessionStorage.setItem('client_id', 'usercenter');

        if (permPaths.length) {
          history.push(permPaths[0] + location.search);
        } else if (sessionStorage.getItem('access_token')) {
          history.push('/uc/users' + location.search);
        }
        return;
      }
    },
    menuHeaderRender: undefined,
    menuDataRender: (menuData) =>
      (!isSelf && ((initialState && initialState.menuData) || menuData)) || [],
    // [{ name: 'JAMES' }],
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
    ...siderWidth,
  };
};
