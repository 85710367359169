// import { outLogin } from '@/services/ant-design-pro/api';
import { logout } from '@/pages/Account/service';
import ResetPasswordForm from '@/pages/UserCenter/UserManagement/ResetPasswordForm/index';
import { clearLoginStorage } from '@/utils/common.utils';
import { LogoutOutlined, SettingOutlined, UserOutlined, PicLeftOutlined } from '@ant-design/icons';
import { Avatar, Menu, Modal, Spin } from 'antd';
// import { stringify } from 'querystring';
import type { MenuInfo } from 'rc-menu/lib/interface';
import React, { Fragment, useCallback, useState } from 'react';
import { history, useModel } from 'umi';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
// import conf from '@/utils/conf';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = () => {
  try {
    clearLoginStorage();
    logout();
  } catch (e) {
    //Nothing
  }
  // const { query = {}, pathname } = history.location;
  // const { redirect } = query;
  // // Note: There may be security issues, please note
  // if (window.location.pathname !== '/user/login' && !redirect) {
  //   clearLoginStorage();
  //   history.replace({
  //     pathname: '/user/login',
  //     search: stringify({
  //       redirect: pathname,
  //     }),
  //   });
  //   location.reload();
  // }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [pwdFormVisible, setPwdFormVisible] = useState(false);

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout') {
        setInitialState((s) => ({ ...s, currentUser: undefined }));
        loginOut();
        return;
      } else if (key === 'resetPassword') {
        setPwdFormVisible(true);
        return;
      } else if (key == 'person') {
        sessionStorage.removeItem('client_id');
        location.href = `${
          location.origin
        }/iam/authorize?response_type=token&client_id=portal&redirect_uri=${encodeURIComponent(
          location.origin + '/uc/selfInfo',
        )}`;
        return;
      } else if (key === 'userEdit') {
        const permMenus = JSON.parse(sessionStorage.getItem('permMenus'));
        history.push(`/uc/users/${permMenus.username}/edit`);
      } else history.push(`/user/${key}`);
    },
    [setInitialState],
  );

  const onClosePwdForm = () => {
    setPwdFormVisible(false);
  };

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.tenant_id) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {
        <Menu.Item key="userEdit">
          <UserOutlined />
          个人中心
        </Menu.Item>
      }
      {
        <Menu.Item key="resetPassword">
          <SettingOutlined />
          修改密码
        </Menu.Item>
      }
      {
        <Menu.Item
          key="person"
          style={{ display: currentUser.username == 'admin' ? 'none' : 'block' }}
        >
          <PicLeftOutlined />
          工作台
        </Menu.Item>
      }
      {menu && <Menu.Divider />}

      <Menu.Item key="logout">
        <LogoutOutlined style={{ marginRight: '5px' }} />
        退出登录
      </Menu.Item>
    </Menu>
  );
  return (
    <Fragment>
      <Modal
        visible={pwdFormVisible}
        width={600}
        footer={null}
        destroyOnClose
        onCancel={onClosePwdForm}
      >
        <ResetPasswordForm
          username={currentUser.username || ''}
          inDlg={true}
          onClose={onClosePwdForm}
        />
      </Modal>
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span className={`${styles.action} ${styles.account}`}>
          <Avatar size="small" className={styles.avatar} src={currentUser.picture} alt="avatar" />
          <span className={`${styles.name} anticon`}>
            {currentUser.username || currentUser.tenant_id}
          </span>
        </span>
      </HeaderDropdown>
    </Fragment>
  );
};

export default AvatarDropdown;
