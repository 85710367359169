// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/lib/jenkins/workspace/IAM-Console-Antd/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/dingding",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Login__Dingding' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/Account/Login/Dingding'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Login' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/Account/Login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "login",
                "path": "/user/login1",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Login' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/Account/Login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "resetPassword",
                "path": "/user/resetPassword",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__ResetPasswordPage' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/ResetPasswordPage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "personPortal",
        "path": "/portal",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Person' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/Person'), loading: LoadingComponent}),
        "layout": false,
        "hideInMenu": true,
        "exact": true
      },
      {
        "name": "users",
        "icon": "icon-user",
        "path": "/uc",
        "routes": [
          {
            "path": "/uc",
            "redirect": "/uc/users",
            "exact": true
          },
          {
            "name": "management",
            "path": "/uc/users",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "userTag",
            "path": "/uc/userTag",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Tag__TagList' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Tag/TagList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "editTagDynamic",
            "path": "/uc/userTag/editDynamic",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Tag__EditTagDynamic' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Tag/EditTagDynamic'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "editTag",
            "path": "/uc/userTag/edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Tag__EditTag' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Tag/EditTag'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "add",
            "path": "/uc/users/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__CreateOrEdit' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/CreateOrEdit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "profile",
            "path": "/uc/usersDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__UserProfile' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/UserProfile'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "selfInfo",
            "path": "/uc/selfInfo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__UserProfile__Person' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/UserProfile/Person'), loading: LoadingComponent}),
            "layout": false,
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "userInfoLs",
            "path": "/uc/userInfoLs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__AppCenter__userInfo' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/AppCenter/userInfo'), loading: LoadingComponent}),
            "layout": false,
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "edit",
            "path": "/uc/users/:username/edit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__CreateOrEdit' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/CreateOrEdit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "linker",
            "path": "/uc/linker",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Linker' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Linker'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "linker.add",
            "path": "/uc/linker/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Linker__CreateOrEdit' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Linker/CreateOrEdit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "linker.edit",
            "path": "/uc/linkerDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Linker__CreateOrEdit' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Linker/CreateOrEdit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "syncgene",
            "path": "/uc/syncgene",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__SyncGene' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/SyncGene'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "syncgene.add",
            "path": "/uc/syncgene/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__SyncGene__CreateOrEdit' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/SyncGene/CreateOrEdit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "syncgene.edit",
            "path": "/uc/syncgeneDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__SyncGene__CreateOrEdit' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/SyncGene/CreateOrEdit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "ladpService",
            "path": "/uc/ladpService",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__LadpService' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/LadpService'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "userApplyfor",
            "path": "/uc/userapplyfor",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Applyfor' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Applyfor'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "userApplyforControl",
            "path": "/uc/userapplyforControl",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Applyfor__mainControl' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Applyfor/mainControl'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "userApplyforRenewal",
            "path": "/uc/userApplyforRenewal",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Applyfor__renewal' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Applyfor/renewal'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "userApplyAdd",
            "path": "/uc/userapplyAdd",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Applyfor__addApply' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Applyfor/addApply'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "userDetilsInfo",
            "path": "/uc/userdetilsInfo",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__Applyfor__userInfo' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/Applyfor/userInfo'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "expireUser",
            "path": "/uc/expireUser",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__ExpireUser' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/ExpireUser'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "expireUserDetail",
            "path": "/uc/expireUserDetail",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__ExpireUserDetail' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/ExpireUserDetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "importResult",
            "path": "/uc/importResult",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__ImportResult' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/ImportResult'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "app",
        "icon": "icon-app",
        "path": "/apps",
        "routes": [
          {
            "path": "/apps",
            "redirect": "/apps/list",
            "exact": true
          },
          {
            "name": "list",
            "path": "/apps/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppList' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "list.view",
            "path": "/apps/appDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppList__AppDetails' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppList/AppDetails'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "list.edit",
            "path": "/apps/appEdit",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppList__EditApp' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppList/EditApp'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "list.add",
            "path": "/apps/appAdd",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppList__EditApp' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppList/EditApp'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "list.market",
            "path": "/apps/applicMaket",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppMarket' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppMarket'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "list.Details",
            "path": "/apps/applicMaket/applicDetails",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppMarket__ApplicationDetails__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppMarket/ApplicationDetails/index'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "list.Configuration",
            "path": "/apps/applicMaket/applicConfig",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppMarket__AppConfig' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppMarket/AppConfig'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "abmapps",
            "path": "/apps/abmapps",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AbmApps' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AbmApps'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "entapps",
            "path": "/apps/entapps",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__EnterpriseApps' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/EnterpriseApps'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "corpcert",
            "path": "/apps/corpcert",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CorpCert' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/CorpCert'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "createClassification",
            "path": "/apps/createClassification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__Classification' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/Classification'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "devices",
        "icon": "icon-device",
        "path": "/devices",
        "routes": [
          {
            "path": "/devices",
            "redirect": "/devices/list",
            "exact": true
          },
          {
            "name": "list",
            "path": "/devices/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DevicesCenter__DevicesList' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/DevicesCenter/DevicesList'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "nofunc",
        "path": "/nofunc",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__noFunc__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/noFunc/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "policy",
        "icon": "icon-policy",
        "path": "/policy",
        "routes": [
          {
            "path": "/policy",
            "redirect": "/policy/appPolicy",
            "exact": true
          },
          {
            "name": "appPolicy",
            "path": "/policy/appPolicy",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PolicyCenter__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/PolicyCenter/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "appPolicy.editPolicy",
            "path": "/policy/appPolicy/editPolicy",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PolicyCenter__EditAppPolicy__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/PolicyCenter/EditAppPolicy/index'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "deviceAdmitPolicy.editPolicy",
            "path": "/policy/deviceAdmitPolicy/editPolicy",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PolicyCenter__EditDevicePolicy__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/PolicyCenter/EditDevicePolicy/index'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "deviceAdmitPolicy",
            "path": "/policy/deviceAdmitPolicy",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PolicyCenter__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/PolicyCenter/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "newsList",
        "icon": "icon-msg",
        "path": "/newsList",
        "routes": [
          {
            "path": "/newsList",
            "redirect": "/newsList/newsList",
            "exact": true
          },
          {
            "name": "newsList",
            "path": "/newsList/newsList",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__NewsList' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/NewsList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "newsDetail",
            "path": "/newsList/newsList/newsDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__NewsList__NewsDetail' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/NewsList/NewsDetail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "fileAudit",
        "icon": "icon-fileAudit",
        "path": "/fileAudit",
        "routes": [
          {
            "path": "/fileAudit",
            "redirect": "/fileAudit/fileAuditLog",
            "exact": true
          },
          {
            "name": "fileAuditLog",
            "path": "/fileAudit/fileAuditLog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FileAuditLog' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/FileAuditLog'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "log",
        "icon": "icon-app",
        "path": "/log",
        "routes": [
          {
            "path": "/log",
            "redirect": "/log/loginLog",
            "exact": true
          },
          {
            "name": "loginLog",
            "path": "/log/loginLog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LogAudit__LoginLog' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/LogAudit/LoginLog'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "systemLog",
            "path": "/log/systemLog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LogAudit__SystemLog' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/LogAudit/SystemLog'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "permissions",
        "icon": "icon-auth",
        "path": "/permissions",
        "routes": [
          {
            "path": "/permissions",
            "redirect": "/permissions/users",
            "exact": true
          },
          {
            "name": "users",
            "path": "/permissions/users",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Permissions__Users' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/Permissions/Users'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "usersDetail",
            "path": "/permissions/users/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserCenter__UserManagement__UserProfile' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/UserCenter/UserManagement/UserProfile'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "roles",
            "path": "/permissions/roles",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Permissions__Roles' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/Permissions/Roles'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "setting",
        "icon": "icon-setting",
        "path": "/setting",
        "routes": [
          {
            "path": "/setting",
            "redirect": "/setting/editSetting",
            "exact": true
          },
          {
            "name": "editSetting",
            "path": "/setting/editSetting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EditSetting__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/EditSetting/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "templates",
            "path": "/setting/templates",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MsgTemplate__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/MsgTemplate/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "licencePage",
            "path": "/setting/licencePage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LicencePage__index' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/LicencePage/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "invitations",
            "path": "/setting/invitations",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__AppInvitations' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/AppInvitations'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "customize",
            "path": "/setting/customize",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AppCenter__CustomizeSettings' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/AppCenter/CustomizeSettings'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "noPermissise",
        "path": "/noPermissise",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Nopromise' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/Nopromise'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/lib/jenkins/workspace/IAM-Console-Antd/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
